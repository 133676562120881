<template>
    <div class="cardCustom" @click="this.$emit('click', this.obj)">
        <div class="cardCustom_body">
            <div class="cardCustom_body_left">
                <div class="cardCustom_body_left__icon">
                    {{ this.getInitials(this.obj.whence) }}
                </div>
                <div class="cardCustom_body_left__text">
                    <span class="name">
                        {{ this.obj.whence }}
                    </span>
                    <span class="reviewsCount">
                        Кількість відгуків: {{ this.obj.totalReviews }}
                    </span>
                </div>
            </div>
            <div class="cardCustom_body_right">
                <div class="cardCustom_body_right__rating">
                    <i class="ri-star-fill" :style="this.getColorOfRating(Number(this.obj.avgRating).toFixed(2))"></i>
                    {{ Number(this.obj.avgRating).toFixed(2) }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: ['obj'],
    methods: {
        getColorOfRating(rating) {
            if(rating >= 4) {
                return 'color: #0ab39c'
            } else if(rating < 3) {
                return 'color: red'
            } else {
                return 'color: #ff9d00'
            }
        },
        getInitials() {
            if(this.obj.whence.split(' ').length > 1) {
                let whenceArray = this.obj.whence.split(' ')
                return whenceArray[0].charAt(0).toUpperCase() + whenceArray[1].charAt(0).toUpperCase()
            } else {
                return this.obj.whence.charAt(0).toUpperCase() + this.obj.whence.charAt(1).toUpperCase()
            }
        }
    }
}

</script>

<style>
    .cardCustom {
        width: 400px;
        height: 100px;
        padding: 24px;
        background-color: white;
        border-radius: 16px;
        box-shadow: 0px 6px 20px 0px #eaeaea
    }
    .cardCustom_body{
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 80% 20%;
    }
    .cardCustom_body_left {
        display: flex;
        gap: 10px;
    }
    .cardCustom_body_left__icon {
        height: 100%;
        background-color: #0070FFB8;
        width: 52px;
        border-radius: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: white;
        font-weight: 700;
    }
    .cardCustom_body_left__text {
        display: flex;
        flex-direction: column;
    }
    .name {
        font-size: 16px;
        font-weight: 700;
    }
    .reviewsCount {
        color: #8a8a8a;
        font-weight: 400;
        font-size: 14px;
    }
    .cardCustom_body_right {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .cardCustom_body_right__rating {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        font-size: 20px;
    }
</style>