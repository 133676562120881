<template>

    <!-- 
        Шапка розділу
        - objSection - 
        - - title - назва розділу
        - - desc - опис розділу
        - - checkBeta - бейдж на бету
        - - checkNew - бейдж на new
    -->
    <headerSection 
        :objSection="objSection"
    />

    <!-- 
        Фільтри
    -->
    <filterSection 
        :objFilter="objFilter"
        @changeTab="changeMode"
    />

    <!-- Таблиця з розділами з відгуками -->
    <tablecustom
        v-if="objFilter.defaultType === 1"
        :rows="rows"
        :columns="columns"
        :objParams="objParams"
        @open="open"
    />

    <div 
        v-if="objFilter.defaultType === 0" 
        style="display: flex; gap: 20px; padding: 20px 0;"
    >
        <categoryCard 
            v-for="items in rows"
            :obj="items"
            @click="open"
        />
    </div>
    
</template>
<script>
import headerSection from '@/components/headerSection/headerSection.vue';
import filterSection from '@/components/headerSection/filterSection.vue';
import tablecustom from '@/components/globaltable/index.vue'
import categoryCard from './components/categoryCard.vue'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм
import { Reviews } from '../../../API_Dash';
import { storeS } from '@/store'; // глобальне сховище даних

let apiServe = new Reviews()

export default {
    data() {
        return {
            objSection: {
                title: "Відгуки",
                desc: "В цьому розділі ви можете переглянути відгуки клієнтів по новим розділам",
                checkBeta: true,
                checkNew: false,
            },
            objFilter: {
                defaultType: 1, // grid
                checkChangeTab: true, // чи відображати можливість перемикання відображення (картки, таблиця)
                checkSearch: true, // статус відображення пошуку
                checkChangeLimit: true, // статус відображення зміни ліміту
                checkAddBtn: false, // статус відображення кнопки "додати"
                checkMoreFilter: false, // статус відображення кнопки більше фільтрів
            },
            columns: [
                {
                    name: this.$t("Section"),
                    text: "whence",
                    align: "left",
                    status: true,
                    copy: true,
                },
                {
                    name: this.$t("avgRating"),
                    text: "avgRating",
                    align: "left",
                    status: true,
                    copy: true,
                },
                {
                    name: this.$t("totalQuantReviews"),
                    text: "totalReviews",
                    align: "left",
                    status: true,
                    copy: true,
                },
            ],
            showCard: false,
            objCard: {},
            objParams: {
                page: '1',
                pageLimit: '10',
            },
            rows: [],
        }
    },
    components: {
        tablecustom,
        headerSection,
        filterSection,
        categoryCard
    },
    created() {
        this.getData()
    },
    methods: {
        changeMode(mode) {
            this.objFilter.defaultType = mode
        },
        getData(page) {
            this.objParams.page = page != undefined ? page : '1';
            apiServe.getSections(this.objParams, ['']).then(result => {
                if(result.status === 'done') {
                    this.rows = result.data.reviews
                    console.log(this.rows.length)
                } else {
                    
                }
            })
        },
        open(e) {
            this.$router.push({ 
                name: 'pages-list-open-reviewsFromClient', 
                params: { 
                    id: e.whence,
                    obj: e, 
                    mode: this.objFilter.checkChangeTab,
                } 
            })
        },
        handleError(error) {
            //відображення помилки
            switch(error){
                case 'condition':
                    this.$toast.error(this.t('translation_key'));
                    break;
            }
        },
    },
    mounted(){
        if (storeS.perms[666]) {
            document.querySelector('#app').style.background = 'white';
        }

    },
}
</script>

<style>
</style>